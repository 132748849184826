
import { defineComponent } from "vue";

export default defineComponent({
  name: "Error",
  props: {
    message: String,
    status: Number,
  },
});
